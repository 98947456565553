"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var ScrollAction = /** @class */ (function () {
    function ScrollAction(usePolyfill) {
        if (usePolyfill === void 0) { usePolyfill = true; }
        this.options = {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        };
        if (usePolyfill) {
            this.polyfill();
        }
    }
    ScrollAction.prototype.polyfill = function () {
        var isIntersectionObserverSupported = 'IntersectionObserver' in window;
        if (!isIntersectionObserverSupported) {
            var script = document.createElement('script');
            script.src =
                'https://polyfill.io/v2/polyfill.min.js?features=IntersectionObserver';
            document.body.appendChild(script);
        }
    };
    /**
     * option
     */
    ScrollAction.prototype.getOptions = function (customOptions) {
        if (customOptions === void 0) { customOptions = {}; }
        return __assign(__assign({}, this.options), customOptions);
    };
    /**
     * 一度しか発火しないアクション
     * @param elements      ターゲットとなるjQuery Object
     * @param callback      発火時に実行する関数
     * @param customOptions カスタムIntersectionObserverオプション
     */
    ScrollAction.prototype.singleAction = function (elements, callback, customOptions) {
        var _this = this;
        if (customOptions === void 0) { customOptions = {}; }
        var options = this.getOptions(customOptions);
        var observer = new IntersectionObserver(function (entries, object) {
            _this.singleActionCallback(entries, object, callback);
        }, options);
        elements.each(function (index, e) {
            observer.observe(e);
        });
    };
    ScrollAction.prototype.singleActionCallback = function (entries, object, callback) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                var target = entry.target;
                callback(target);
                object.unobserve(target);
            }
        });
    };
    /**
     * カスタムアクション
     * @param  elements      ターゲットとなるjQuery Object
     * @param  callback      コールバック関数
     * @param  customOptions カスタムIntersectionObserverオプション
     */
    ScrollAction.prototype.customAction = function (elements, callback, customOptions) {
        if (customOptions === void 0) { customOptions = {}; }
        var options = this.getOptions(customOptions);
        var observer = new IntersectionObserver(callback, options);
        elements.each(function (index, e) {
            observer.observe(e);
        });
    };
    return ScrollAction;
}());
exports.default = ScrollAction;
